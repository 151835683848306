import * as React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import Navbar from "../components/navbar";
import HeroInner from '../components/hero-inner';
import BlogContent from '../components/blog-content';
import SIDEBAR from '../components/s-i-d-e-b-a-r';
import Footer from '../components/footer';

const BlogPost = styled.section`
	align-self: stretch;
	background-color: var(--dominant);
	box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: var(--padding-71xl) var(--padding-101xl);
	gap: var(--gap-81xl);
	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
`;
const BlogRoot = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: center;
	font-size: var(--font-size-2xl);
	color: var(--dominant);
	// font-family: var(--text-tiny-link);
`;
const BlogOrderedList = styled.ol`
	margin: 0;
	font-size: inherit;
	padding-left: var(--padding-5xl);
  margin-block-end: 1.875rem
`;
const BlogListItem = styled.li`
	margin-bottom: 12px;
  b {display: flex};
`;


const Blog = () => {
	return (
		<BlogRoot>
			<Navbar
        continLogo2a1="/continlogo2a-1.svg"
        accountSection={false}
        hamburgerMenu="/notification.svg"
        showHamburgerMenuIcon
        notificationBell="/notification1.svg"
        profilePicture="/top_avatar@2x.png"
        actionButton="Get in Touch"
        iconChevronRight="/icon--chevronright@2x.png"
        showActionButton={false}
        showActionButtonLink={false}
      />
			<HeroInner
				headerTitle={`Navigating the Essential Eight: A Comprehensive Guide to ASD & ACSC Guidelines`}
				tagDate='Compliance  -  Feb 10, 2024'
				headerImage='/rectangle-11@2x.png'
			/>
			<BlogPost>
				<BlogContent
					introKicker='In the ever-evolving landscape of cybersecurity, organizations face the critical task of safeguarding their digital assets against an array of threats. For those operating in Australia, the Australian Signals Directorate (ASD) and the Australian Cyber Security Centre (ACSC) provide indispensable guidance through the Essential Eight—a comprehensive set of strategies designed to enhance cybersecurity resilience.'
					pullQuote='In the pursuit of cybersecurity excellence, let the Essential Eight be your compass, guiding you towards a future of heightened resilience and unwavering security.'
					mainContent={
						<>
							<Typography variant='h3' sx={{ fontFamily: 'Oswald' }}>
								Understanding the Foundation
							</Typography>
							<Typography component='p' variant='p' sx={{marginBlockEnd: '1.875rem'}}>
								The Essential Eight serves as a foundational framework, offering a
              strategic approach to mitigate the most prevalent cybersecurity
              risks. It encompasses a set of prioritized security strategies,
              each meticulously crafted to address specific threat vectors and
              enhance an organization's overall security posture.
							</Typography>
							<Typography variant='h3' sx={{ fontFamily: 'Oswald' }}>
								Key Elements of the Essential Eight.
							</Typography>
							<BlogOrderedList>
								<BlogListItem>
									<b>Application Patching:</b>
									Regularly update and patch applications to address known
									vulnerabilities and weaknesses, ensuring software integrity and
									reducing the risk of exploitation by cyber threats.
								</BlogListItem>
								<BlogListItem>
									<b>Operating System Patching:</b>
									Implement regular updates and patches for operating systems to mitigate
									known vulnerabilities, fortifying system defenses against potential
									cyber attacks and data breaches.
								</BlogListItem>
								<BlogListItem>
									<b>Multi-Factor Authentication (MFA):</b>
									Enhance access security by implementing multi-factor authentication,
									requiring users to provide multiple forms of verification before
									gaining access to sensitive systems or data.
								</BlogListItem>
								<BlogListItem>
									<b>Administrative Privilege Restriction:</b>
									Limit administrative privileges to authorized personnel, minimizing the
									risk of unauthorized access, data breaches, and system compromise.
								</BlogListItem>
								<BlogListItem>
									<b> Application Control:</b>
									Employ application control measures to regulate the execution of
									software, preventing unauthorized applications from running and
									bolstering system security.
								</BlogListItem>
								<BlogListItem>
									<b>Microsoft Office Macro Restriction:</b>
									Mitigate the risk of macro-based malware attacks by restricting the
									execution of Microsoft Office macros, enhancing the security posture of
									your organization's digital assets.
								</BlogListItem>
								<BlogListItem>
									<b>User Application Hardening:</b>
									Strengthen security measures for user applications, such as web
									browsers and email clients, to minimize the risk of exploitation by
									malicious actors and enhance overall system resilience.
								</BlogListItem>
								<BlogListItem>
									<b>Regular Backup Implementation:</b>
									Establish a routine backup strategy to safeguard critical data and
									systems, ensuring resilience against data loss, ransomware attacks, and
									other unforeseen incidents.
								</BlogListItem>
							</BlogOrderedList>
							<Typography component="h3" variant='h3' sx={{ fontFamily: 'Oswald', marginBlockEnd: '1rem' }}>
								Your Path to Cyber Resilience
							</Typography>

							<Typography component='p' variant='p'>
								Navigating the Essential Eight is not just a compliance mandate; it's a
								strategic investment in cyber resilience. Organizations that embrace
								these guidelines position themselves to proactively counter emerging
								threats, safeguard sensitive data, and maintain the trust of their
								stakeholders.
							</Typography>
							<Typography component='p' variant='p'>
								In the coming weeks, we will delve deeper into each of the Essential
								Eight strategies, offering practical insights and actionable steps to
								empower your organization's cybersecurity journey. Stay tuned for a
								comprehensive exploration of each element and discover how to fortify
								your defenses in an ever-changing digital landscape.
							</Typography>
						</>
					}
				/>
				<SIDEBAR />
			</BlogPost>

			<Footer />
		</BlogRoot>
	);
};

export default Blog;
